import { NotificationStyled } from './Notification.styled';

import { Alert } from '@mui/material';

import { useActions, useAppSelector } from '@/hooks';

export const Notification = () => {
  const { isShow, text, severity } = useAppSelector((state) => state.notification);
  const { closeNotification } = useActions();

  return (
    <NotificationStyled
      open={isShow}
      autoHideDuration={3000}
      onClose={() => closeNotification()}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={() => closeNotification()} severity={severity} variant="filled">
        {text}
      </Alert>
    </NotificationStyled>
  );
};
