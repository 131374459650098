import { HeaderStyled, Inner } from './Header.styled';

import Container from '../../../components/Shared/Container/Container';
import Logo from '../../../components/Shared/Logo/Logo';

import { useLocation } from 'react-router-dom';

const Header = () => {
  const { pathname } = useLocation();
  return (
    <HeaderStyled>
      <Container>
        <Inner>{pathname !== '/' && <Logo />}</Inner>
      </Container>
    </HeaderStyled>
  );
};

export default Header;
