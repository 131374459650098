// import { ResultVideoInner } from './ResultVideoInner/ResultVideoInner';
import { ResultVideoInnerWithScreenShot } from './ResultVideoInnerWithScreenShot/ResultVideoInnerWithScreenShot';

import FullScreenLoader from '../../../components/Shared/FullScreenLo/FullScreenLoader';
import { Video } from '../../../types';
import { CountTitle } from '../SearchResultPage/SearchResultPage.styled';

interface ResultInnerProps {
  data?: Video[];
  isLoading: boolean;
  count?: number;
}

export const ResultInner = ({ data = [], isLoading, count = 0 }: ResultInnerProps) => {
  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <CountTitle>{`Найдено ${count} видео`}</CountTitle>
      {/* <ResultVideoInner videos={data} /> */}
      <ResultVideoInnerWithScreenShot videos={data} />
    </>
  );
};
