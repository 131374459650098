import { getMinutes } from './helpers';
import { TimecodeDiscription } from './TimecodeDiscription';
import {
  Time,
  TimecodesWrapper,
  Title,
  TimecodesTitle,
  TimecodesContentWrapper,
  TimecodesTitleWrapper,
  TextLink,
  List,
  ListItem,
  Text,
  MoreButton,
  TextWrapper,
} from './Timecodes.styled';

import { useState } from 'react';

import { useGetTimecodesQuery } from '@/api';
import FullScreenLoader from '@/components/Shared/FullScreenLo/FullScreenLoader';

interface TimecodesProps {
  setTime: (time: number) => void;
  id: string;
  currentTime: number | null;
}

export const Timecodes = ({ setTime, id, currentTime }: TimecodesProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { data, isLoading, isError } = useGetTimecodesQuery(id);
  // const refs = data?.map((_, i) => useRef<HTMLLIElement>(null));

  const timings = data?.map((array) => array.start) || [];

  const highlightChapter = (i: number) => {
    // TODO: make a scroll to current timecodes
    // if (currentTime && currentTime >= timings[i] && currentTime < timings[i + 1]) {
    //   refs?.[i]?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }
    return currentTime && currentTime >= timings[i] && (timings[i + 1] === undefined || currentTime < timings[i + 1]);
  };

  const onReadMoreClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <TimecodesWrapper isCollapsed={isCollapsed}>
      {isLoading && <FullScreenLoader />}
      {isError && <Text>Возникла ошибка при получении таймкодов</Text>}
      {data && (
        <>
          <Title>Таймкоды</Title>
          <List>
            {data.map(({ start, text, title }, i) => (
              <ListItem key={i}>
                {/*  ref={refs?.[i]} */}
                <TimecodesContentWrapper>
                  <TimecodesTitleWrapper onClick={() => setTime(start)}>
                    <Time>{getMinutes(start)}</Time>
                    <TimecodesTitle style={{ backgroundColor: highlightChapter(i) ? '#BE9F4B' : 'transparent' }}>
                      {title}
                    </TimecodesTitle>
                  </TimecodesTitleWrapper>
                  <div />
                  <TimecodeDiscription text={text} />
                </TimecodesContentWrapper>
              </ListItem>
            ))}
          </List>
          <MoreButton theme="inline" onClick={onReadMoreClick}>
            {isCollapsed ? 'Скрыть' : 'Еще'}
          </MoreButton>
        </>
      )}
    </TimecodesWrapper>
  );
};
