import { SearchVideoCardWithScreenShot } from '../../SearchVideoResultPage/SearchVideoCardWithScreenShot';
import { CardsList } from '../../SearchVideoResultPage/SearchVideoResultPage.styled';

import { Video } from '@/types';

// type Video = {};
interface ResultVideoInnerWithScreenShotProps {
  videos: Video[];
}

export const ResultVideoInnerWithScreenShot = ({ videos = [] }: ResultVideoInnerWithScreenShotProps) => {
  return (
    <CardsList>
      {videos.map((video, i) => (
        <SearchVideoCardWithScreenShot key={i} videoInfo={video} />
      ))}
    </CardsList>
  );
};
