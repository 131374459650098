import { FragmentCardProps } from './FragmentCard.props';

import styled from 'styled-components';

import { LabelText } from '@/styles';

export const FragmentCardStyled = styled.div`
  /* height: 251px; */
  width: 320px;
  /* padding: 0 10px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #210071;
`;

export const VideoTime = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #e4e4ff;
  padding: 9px 12px;
  display: flex;
  background: rgba(23, 8, 123, 0.3);
  backdrop-filter: blur(5px);
  width: max-content;
  align-items: center;
  user-select: none;
  margin: 0 auto;
`;

export const VideoImageWrapper = styled.div<Pick<FragmentCardProps, 'background_image'>>`
  background-image: url(${(props) => props.background_image});
  background-size: cover;
  background-position: center;
  height: 142px;
  /* width: 320px; */
`;

export const Description = styled(LabelText)`
  font-size: 14px;
  background-color: #270084;
  padding: 12px 20px 14px;
  color: #d4cce6;
  hyphens: none;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis; /* Добавляем многоточие */
  height: 85px;
`;
export const Time = styled(LabelText)`
  text-align: center;
  font-size: 18px;
  padding: 10px 38px;
`;
