import { Buttons } from './components/Buttons';
import { Timecodes } from './components/Timecodes';
import { Inner, Title, VideoWrapper } from './VideoPage.styled';

import FullScreenLoader from '../../components/Shared/FullScreenLo/FullScreenLoader';

import { useEffect, useRef, useState } from 'react';

import { useLocation, useParams, useSearchParams } from 'react-router-dom';
// eslint-disable-next-line import/named
import YouTube, { YouTubeEvent, YouTubeProps } from 'react-youtube';

import { videosAPI } from '@/api';
import { useMatchMedia, useHandlingError } from '@/hooks';

const VideoPage = () => {
  const playerRef = useRef<YouTubeEvent['target']>(null)
  const [currentTime, setCurrentTime] = useState(null)
  const { id } = useParams();
  const [params] = useSearchParams();

  const { data: video, isLoading, error } = videosAPI.useGetMovieByIdQuery({ id: id ?? '' }, { skip: !id });

  const isTablet = useMatchMedia('(max-width: 768px)');

  const { catchError } = useHandlingError();

  useEffect(() => {
    catchError(error);
  }, [error]);

  const iframe = useRef<YouTube>(null);
  const iframeWrapper = useRef<HTMLDivElement>(null);

  const goToTime = (time: number) => {
    iframe.current?.internalPlayer.seekTo(time, true);
    iframeWrapper.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const getCurrentTimeFunc = async () => {
    setCurrentTime((await iframe.current?.internalPlayer.getCurrentTime()) || 0);
  };

  let timerId: number;
  const onStateChange: YouTubeProps['onStateChange'] = (event) => {
    if (event.data === 1) {
      timerId = setInterval(() => {
        getCurrentTimeFunc();
      }, 1000);
    } else if (event.data === 2) {
      clearInterval(timerId)
    }
  };

  const height = isTablet ? '300px' : '500px';

  const goToTimeFunc = async (event: YouTubeEvent) => {
    await event.target.seekTo(params.get('t') ?? 0, true);
    await event.target.playVideo();
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {video && (
        <Inner>
          <VideoWrapper ref={iframeWrapper}>
            <YouTube
              videoId={video.videoId}
              title={video.title}
              ref={iframe}
              style={{ width: '100%', height }}
              onStateChange={onStateChange}
              onReady={goToTimeFunc}
              opts={{
                height,
                width: '100%',
              }}
            />
          </VideoWrapper>
          <div style={{ display: 'flex', gap: '20px', justifyContent: 'space-between' }}>
            <Title>{video.title}</Title>
            <Buttons id={video.publicId} />
          </div>
          <Timecodes id={video.publicId} setTime={goToTime} currentTime={currentTime} />
        </Inner>
      )}
    </>
  );
};

export default VideoPage;
