import { ButtonsWrapper } from './Timecodes.styled';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '@/components';
import { useAppSelector, useMatchMedia } from '@/hooks';

export const Buttons = ({ id }: { id: string }) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const videos = useAppSelector((state) => state.videos.videos);
  const isMobile = useMatchMedia('(max-width: 480px)');

  const currentIndex = videos.findIndex((item) => item.publicId === id);

  const goNext = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < videos.length) {
      params.set('id', videos[nextIndex].publicId);
      navigate(`/results/${videos[nextIndex].publicId}`);
    }
  };

  const goPrev = () => {
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      navigate(`/results/${videos[prevIndex].publicId}`);
    }
  };

  if (!videos.length) {
    return null;
  }

  return (
    <ButtonsWrapper>
      <Button theme="inline" disabled={currentIndex === 0} onClick={goPrev}>
        Назад
      </Button>
      <Button theme="white" disabled={videos.length === currentIndex + 1} onClick={goNext}>
        {isMobile ? 'Следующее' : 'Следующее видео'}
      </Button>
    </ButtonsWrapper>
  );
};
