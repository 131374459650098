/* eslint-disable @typescript-eslint/no-unused-vars */
import { ButtonsWrapper, FieldsWrapper, Form, PasswordWrapper } from './Login.styled';

import { RegisterUser } from '../../../types/authTypes';
import { isAuthData } from '../../../utils/isAuthData';
import Button from '../Buttons/Button/Button';
import { InnerAuth, Title } from '../Modal';
import MyInput from '../MyInput/MyInput';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import ReactGA from 'react-ga4';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useActions, useAppSelector, useHandlingError, useLocalStorage } from '@/hooks';

export const Login = () => {
  const navigate = useNavigate()
  const { catchError } = useHandlingError();
  const { showNotification } = useActions();
  const searchParam = useAppSelector(state => state.searchQuery.query)

  const closeHandler = () => {
    reset();
    navigate('/')
  };

  const successHandler = () => {
    localStorage.setItem('isAuth', JSON.stringify(true));
    closeHandler();
    showNotification({
      text: 'Добро пожаловать!',
      severity: 'success',
    });
  };

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm<RegisterUser>({ defaultValues: { email: '', password: '' } });

  const onSubmit = async (data: RegisterUser) => {
    try {
      if (data.email.trim() === 'probusiness_1322' && data.password.trim() === 'PROB2020') {
        successHandler();
        ReactGA.event({
          category: 'login',
          action: 'login',
        });
      } else {
        showNotification({
          text: 'Неверные данные',
          severity: 'error',
        });
        return 'Неверные данные';
      }
    } catch (err) {
      const errorData = (err as FetchBaseQueryError).data;
      if (isAuthData(errorData)) {
        const message = Object.values(errorData)
          .map((value) => {
            console.log(value);
            if (value === 'No active account found with the given credentials') {
              return;
            }
            return 'Неверные данные';
          })
          .join(' \n');
      }
      catchError(err);
    }
  };

  return (
    <div>
      <Title>Войти</Title>
      <InnerAuth modal={'login'}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FieldsWrapper>
            <MyInput
              required
              label='Логин'
              {...register('email', {
                required: { value: true, message: 'Поле обязательно для заполнения' },
                minLength: { value: 5, message: 'Минимум 5 символов' },
                maxLength: { value: 256, message: 'Максимум 256 символов' },
              })}
            // error={!!errors.email && `${errors.email}`}
            />
            <PasswordWrapper>
              <MyInput
                required
                label='Пароль'
                type="password"
                {...register('password', {
                  required: { value: true, message: 'Поле обязательно для заполнения' },
                  minLength: { value: 6, message: 'Минимум 6 символов' },
                  maxLength: { value: 128, message: 'Максимум 128 символов' },
                })}
              // error={!!errors.password && `${errors.password}`}
              />
            </PasswordWrapper>
          </FieldsWrapper>
          <ButtonsWrapper>
            <Button theme="white" type="submit">
              Войти
            </Button>
            <Button theme="inline" type="reset" onClick={closeHandler}>
              Отменить
            </Button>
          </ButtonsWrapper>
        </Form>
      </InnerAuth>
    </div>
  );
};
