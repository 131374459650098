import { InputProps } from './MyInput.props';
import { Input, Label, MyInputWrapper, ErrorText, MyLabelText, PasswordIconWrapper } from './MyInput.styled';

import { forwardRef, useReducer } from 'react';

import EyeHiddenIcon from '@/components/SvgIcons/EyeHiddenIcon';
import EyeShowIcon from '@/components/SvgIcons/EyeShowIcon';

const MyInput = forwardRef<HTMLInputElement, InputProps>(
  ({ className, error, onChange, name, label, children, icon, type, required = false, ...props }, ref) => {
    const [showPassword, toggle] = useReducer((prev: boolean) => !prev, false);

    return (
      <MyInputWrapper error={error} className={className}>
        <Label htmlFor={name} itemType={type}>
          {label && <MyLabelText required={required}>{label}</MyLabelText>}
          <Input
            {...props}
            name={name}
            id={name}
            type={showPassword ? 'text' : type}
            ref={ref}
            error={error}
            onChange={onChange}
          />
          {children}
          {icon}
          {type === 'password' && (
            <PasswordIconWrapper onClick={toggle}>
              {showPassword ? <EyeShowIcon /> : <EyeHiddenIcon />}
            </PasswordIconWrapper>
          )}
        </Label>
        {error && <ErrorText>{error}</ErrorText>}
      </MyInputWrapper>
    );
  },
);
MyInput.displayName = 'MyInput';
export default MyInput;
