const EyeShowIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M15.0467 12.27C15.0467 14.0996 13.5889 15.578 11.785 15.578C9.98106 15.578 8.52333 14.0996 8.52333 12.27C8.52333 10.4404 9.98106 8.962 11.785 8.962C13.5889 8.962 15.0467 10.4404 15.0467 12.27Z"
      stroke="#62548B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.785 20.54C15.315 20.54 18.605 18.4613 20.895 14.8634C21.795 13.4543 21.795 11.0857 20.895 9.67657C18.605 6.07874 15.315 4 11.785 4C8.255 4 4.965 6.07874 2.675 9.67657C1.775 11.0857 1.775 13.4543 2.675 14.8634C4.965 18.4613 8.255 20.54 11.785 20.54Z"
      stroke="#62548B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EyeShowIcon;