import modal, { modalsSlice } from './slices/modalsSlice';
import notification, { notificationSlice } from './slices/notificationSlice';
import quiz, { quizSlice } from './slices/quizSlice';
import searchQuery, { searchQuerySlice } from './slices/searchQuery';
import videos from './slices/videoSlice';

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import type { Action, ThunkAction } from '@reduxjs/toolkit';

import { api, quiz as quizApi, timecodeApi } from '@/api';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [timecodeApi.reducerPath]: timecodeApi.reducer,
    [quizApi.reducerPath]: quizApi.reducer,
    notification,
    videos,
    modal,
    searchQuery,
    quiz,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      api.middleware,
      timecodeApi.middleware,
      quizApi.middleware,
    ),
  devTools: import.meta.env.MODE !== 'production',
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const rootActions = {
  ...notificationSlice.actions,
  ...modalsSlice.actions,
  ...searchQuerySlice.actions,
  ...quizSlice.actions,
};
