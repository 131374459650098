import { SuggestionSearchWrapper } from './SearchInput.styled';

import { SearchResultInput } from '../../SearchResultPage.styled';

import React, { ChangeEvent, RefObject, createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ga4 from 'react-ga4';
import { useSearchParams } from 'react-router-dom';

import {
  useGetSearchVideosQuery,
  useGetSuggestionsQuery,
  useLazyGetSearchVideosQuery,
  useLazyGetSuggestionsQuery,
} from '@/api/searchApi';
import { SearchIcon } from '@/components';
import { useDebounce, useOutsideClick } from '@/hooks';
import { highlightText } from '@/pages/Search/components/InputWithDropDown/InputWithDropDown';
import { SearchWrapper, SuggestionsItem, SuggestionsList, SuggestionsWrapper } from '@/pages/Search/SearchPage.styled';
import { LabelText } from '@/styles';

export const SearchInput = ({ suggestionsList }: { suggestionsList: string[] }) => {
  const [params, setParams] = useSearchParams();

  const search = useRef<HTMLInputElement | null>(null);

  const [suggestions, setSuggestions] = useState<string[]>(suggestionsList);
  const [selectedSuggestion, setSelectedSuggestion] = useState(-1);
  const [open, setOpen] = useState(false);

  const [getSuggestions] = useLazyGetSuggestionsQuery();
  const [getVideos] = useLazyGetSearchVideosQuery();

  const wrapperRef = useOutsideClick<HTMLDivElement>(() => {
    setOpen(false);
  });

  useGetSearchVideosQuery({ query: search.current?.value || params.get('search') }, { skip: !params.get('search') });

  const debounceSuggestions = useDebounce(async (query: string) => {
    try {
      const data = query ? await getSuggestions({ query }).unwrap() : [];
      setOpen(true);
      setSuggestions(data);
    } catch (error) {
      console.log(error);
    }
  }, 250);

  const onSearchHandler = () => {
    const value = search.current?.value;
    if (value) {
      pickSuggestion(value);
    }
  };

  const pickSuggestion = (value: string) => {
    search.current && (search.current.value = value);
    getVideos({ query: value });
    setOpen(false);
    setParams((prev) => ({ ...prev, search: value }));
    ga4.event({
      category: 'Search',
      action: 'Search',
      label: 'Search',
    });
  };

  const makeSearch = useDebounce(() => {
    const data = search.current?.value || '';
    if (data) {
      setParams({ search: data });
    } else {
      setParams({});
    }
  }, 500);

  const onChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    await debounceSuggestions(e.target.value);
    makeSearch();
  }, []);

  const refs = useMemo(
    () =>
      suggestions.reduce((acc, item) => {
        acc[item] = createRef<HTMLLIElement>();
        return acc;
      }, {} as Record<string, RefObject<HTMLLIElement>>),
    [suggestions],
  );

  const scrollToRef = (value: string, block: 'start' | 'end' | 'center' | 'nearest') => {
    const ref = refs[value].current;
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth', block });
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    const searchInput = search.current;
    if (selectedSuggestion < suggestions.length) {
      switch (e.key) {
        case 'Escape':
          setSelectedSuggestion(-1);
          setOpen(false);
          break;
        case 'Enter':
          onSearchHandler();
          searchInput?.blur();
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (selectedSuggestion > 0) {
            setSelectedSuggestion((prev) => prev - 1);
            searchInput && (searchInput.value = suggestions[selectedSuggestion - 1]);
            scrollToRef(suggestions[selectedSuggestion - 1], 'center');
          } else {
            setSelectedSuggestion(-1);
          }

          break;
        case 'ArrowDown':
          e.preventDefault();
          if (selectedSuggestion < suggestions.length - 1) {
            setSelectedSuggestion((prev) => prev + 1);
            searchInput && (searchInput.value = suggestions[selectedSuggestion + 1]);

            scrollToRef(suggestions[selectedSuggestion + 1], 'end');
          } else {
            setSelectedSuggestion(suggestions.length - 1);
          }
          break;
        default:
          break;
      }
    } else {
      setSelectedSuggestion(-1);
    }
  };

  return (
    <SearchWrapper ref={wrapperRef}>
      <SearchResultInput
        icon={<SearchIcon />}
        ref={search}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={() => setOpen(true)}
        defaultValue={params.get('search')?.replace(/(<([^>]+)>)/gi, '') ?? ''}
        placeholder={'Введите запрос или опишите видео'}
      />
      {suggestions.length > 0 && open && (
        <SuggestionSearchWrapper>
          <SuggestionsList>
            {suggestions.map((suggestion, i) => (
              <SuggestionsItem
                key={suggestion}
                onClick={(e) => {
                  e.stopPropagation();
                  pickSuggestion(suggestion);
                }}
                selected={selectedSuggestion === i}
                ref={refs[suggestion]}
                onMouseEnter={() => {
                  setSelectedSuggestion(i);
                }}
              >
                <LabelText dangerouslySetInnerHTML={{ __html: highlightText(suggestion, search.current!.value) }} />
              </SuggestionsItem>
            ))}
          </SuggestionsList>
        </SuggestionSearchWrapper>
      )}
    </SearchWrapper>
  );
};
