import { theme } from './theme';

import styled, { css } from 'styled-components';

export const TitleH1 = styled.h1`
  font-weight: 700;
  font-size: 48px;
  line-height: 67px;
`;

export const TitleH2 = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 45px;
  @media screen and (max-width: 1024px) {
    font-size: 28px;
  }
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

export const TitleH3 = styled.h3`
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
  color: ${theme.colors.text.white_100};

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const TitleH4 = styled.h4`
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: ${theme.colors.text.white_100};

  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 480px) {
    font-size: 17px;
  }
`;

export const TitleH5 = styled.h5`
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: ${theme.colors.text.white_100};
`;

export const Text = styled.p`
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  @media (max-width: 480px) {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
  }
`;

export const SecondaryText = styled.p`
  font-weight: 300;
  font-size: 17px;
  line-height: 24px;
  color: ${theme.colors.text.white_80};

  @media (max-width: 1028px) {
    font-size: 15px;
  }
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

export const ButtonText = styled.p`
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: ${theme.colors.text.white_100};
`;

export const LabelText = styled.p`
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: ${theme.colors.text.white_100};
`;

export const DescriptionText = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: ${theme.colors.text.white_100};
`;

export const StatText = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
`;

export const FooterText = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
`;

export const CardsList = styled.div`
  width: 100%;
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  /* margin-bottom: 80px; */
  list-style: none;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
