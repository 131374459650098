import styled from 'styled-components';

import { Button, MyInput } from '@/components';
import { TitleH2, theme } from '@/styles';

export const SearchStyled = styled.main``;

export const SearchSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 100%;
  padding-top: 3.7%;
  background: radial-gradient(146.77% 99.87% at 96.01% 40.43%, #150675 0%, #0b012c 100%), #0b002c,
    linear-gradient(180deg, rgba(155, 36, 201, 0.5) 0%, rgba(0, 174, 148, 0.5));

  @media screen and (max-width: 1000px) {
    padding-top: 0;
  }
`;

export const Title = styled(TitleH2)`
  color: #dad3ff;
  text-align: center;
  line-height: 56px;
  text-transform: uppercase;
  min-width: 320px;
`;

export const TextWithLogo = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
  @media screen and (max-width: 785px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

export const SearchButton = styled(Button)`
  padding: 12px 48px;
`;

export const SearchInput = styled(MyInput)`
  input {
    border-radius: 10px;
    border: 2px solid #e4e4ff;
    display: flex;
    width: 800px;
    padding: 12px 16px 14px 60px;
    align-items: center;
    gap: 10px;
    color: ${theme.colors.white.white_100};
    transition: color 3s ease-in;
    ::placeholder {
      color: inherit;
    }
    :focus {
      ::placeholder {
        color: ${theme.colors.white.white_40};
      }
    }
    @media screen and (max-width: 1000px) {
      width: 80vw;
    }
    @media screen and (max-width: 480px) {
      padding-left: 40px;
    }
  }

  svg {
    position: absolute;
    top: 25%;
    left: 2%;
    path {
      stroke: #e4e4ff;
    }
  }
`;

export const SearchLogo = styled.img`
  height: auto;
  width: 32rem;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

//------------------------------------------------------
export const InputWrapper = styled.div`
  position: relative;
`;

export const SuggestionsWrapper = styled.div`
  position: absolute;
  background-color: ${theme.colors.blue.blue_dark};
  width: 100%;
  border: 3px solid ${theme.colors.white.white_100};
  border-radius: 10px;
  z-index: 1;
  max-height: 230px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 15px; /* ширина scrollbar */
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 20px; /* цвет дорожки */
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: ${theme.colors.blue.blue_5}; /* цвет плашки */
  }
`;

export const SuggestionsList = styled.ul``;

export const SuggestionsItem = styled.li<{ selected: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? theme.colors.blue.blue_20 : 'transparent')};
  :hover {
    background-color: ${theme.colors.blue.blue_20};
  }
  :first-child,
  :last-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  :last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  transition: background-color 0.3s ease;
`;
