import styled from 'styled-components';

export const LogoStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`;

export const LogoImg = styled.img`
  height: 64px;
  width: 280px;
`;
