import { useAuth } from '../../hooks'

import { FC } from 'react'

import { Navigate } from 'react-router-dom'


export const ProtectedRoute: FC<any> = ({ component: Component, ...props }) => {
  const isAuth = useAuth()
  return isAuth ? <Component {...props} /> : <Navigate to='/login' replace />
}