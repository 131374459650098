import { Button } from '../Button';

import { useNavigate } from 'react-router-dom';

const GoHomeButton = () => {
  const navigate = useNavigate();

  return (
    <Button size="big" onClick={() => navigate('/')}>
      Назад
    </Button>
  );
};

export default GoHomeButton;
