import { baseQueryMock } from './baseQueryMock';

import { createApi } from '@reduxjs/toolkit/query/react';


export const quizApi = createApi({
  baseQuery: baseQueryMock,
  endpoints: () => ({}),
  refetchOnReconnect: true,
});
