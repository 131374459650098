import { Wrapper } from './FullScreenLoaderStyle';

import CircularProgress from '@mui/material/CircularProgress';

const FullScreenLoader = () => {
  return (
    <Wrapper>
      <CircularProgress size={'60px'} color="inherit" />
    </Wrapper>
  );
};

export default FullScreenLoader;
