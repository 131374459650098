import { TitleH2 } from '../../../styles';
import { SearchInput } from '../SearchPage.styled';

import styled from 'styled-components';

export const CountTitle = styled(TitleH2)`
  margin-bottom: 30px;
  margin-left: 0;
`;

export const CardsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 290px);
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const SearchResultInput = styled(SearchInput)`
  width: 100%;
  margin-bottom: 12px;
  /* margin-bottom: 60px;

  @media screen and (max-width: 480px) {
    margin-bottom: 20px;
  } */

  input {
    width: 100%;
  }
`;

export const FilterAndInputWrapper = styled.div`
  margin-bottom: 48px;
`;
