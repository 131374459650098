import { baseQuery } from './baseQuery';

import { createApi } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  reducerPath: 'visaver',
  tagTypes: ['movies', 'searchAI'],
  baseQuery,
  endpoints: () => ({}),
  refetchOnReconnect: true,
});
