import { LogoStyled, LogoImg } from './Logo.styled';

import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <LogoStyled>
      <Link to="/" replace>
        <LogoImg src="/images/PROtreking20.png" />
      </Link>
    </LogoStyled>
  );
};

export default Logo;
