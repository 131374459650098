import { SearchWithButton } from './components/SearchWithButton/SearchWithButton';
import { SearchSection, Title, SearchLogo } from './SearchPage.styled';

export const Search = () => {
  return (
    <SearchSection>
      <SearchLogo src="/images/PROtreking20.png" />
      <Title>найти видеофрагмент</Title>
      <SearchWithButton />
    </SearchSection>
  );
};
