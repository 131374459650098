import { TitleH4 } from '../../styles/components';

import styled from 'styled-components';

export const Wrapper = styled.div`
  align-self: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  margin: 40px 0;
  @media screen and (max-width: 1024px) {
    margin: 20px 0;
  }
  @media screen and (max-width: 480px) {
    margin: 10px 0;
  }
`;

export const Title = styled(TitleH4)`
  margin: 0 0 30px 0;
`;

export const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 500px;
`;
export const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
`;
