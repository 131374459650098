import { Wrapper } from './PlusButton.styled';

import React from 'react';

import { ArrowLeft } from '@/components/SvgIcons';
import ArrowRight from '@/components/SvgIcons/ArrowRight';

interface PlusButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: 'left' | 'right';
  className?: string;
}

export const ArrowButton: React.FC<PlusButtonProps> = ({ onClick, variant = 'left', className }) => {
  return (
    <Wrapper onClick={onClick} variant="withIcon" className={className}>
      {variant === 'right' ? <ArrowRight /> : <ArrowLeft />}
    </Wrapper>
  );
};
