import { TitleH3 } from '../../styles/components';
import { theme } from '../../styles/theme';

import styled from 'styled-components';

export const Wrapper = styled.div``;
export const Title = styled(TitleH3)``;

export const Inner = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media screen and (max-width: 768px) {
    gap: 25px;
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  background-color: ${theme.colors.blue.blue_5};
  display: flex;
  justify-content: center;
  background-image: url('https://catherineasquithgallery.com/uploads/posts/2021-02/1612753659_156-p-fon-gradient-goluboi-fioletovii-180.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  box-shadow: 0px 4px 75px 50px rgba(11, 0, 44, 0.49);
`;
