import { timecodeApi } from './timecodeApi';

import {SummaryResponse, Timecode, TimecodesResponse } from '@/types';


export const timecodes = timecodeApi.injectEndpoints({
  endpoints: (build) => ({
    getTimecodes: build.query<Timecode[], string>({
      query: (video_id) => ({
        url: `/videos/${video_id}/timecodes/`,
        method: 'GET',
      }),
      transformResponse: (response: TimecodesResponse) =>
        response.results[0].data.timecodes
          .map((timecode) => ({ ...timecode, start: Math.round(timecode.start) }))
          .sort((a, b) => a.start - b.start),
    }),

    getDocs: build.query<string, string>({
      query: (query) => ({
        url: `/videos/${query}/summary/`,
        method: 'GET',
      }),
      transformResponse: (response: SummaryResponse) =>  response.results[0].file,
    }),
  }),
});

export const { useGetTimecodesQuery, useLazyGetDocsQuery } = timecodes;
