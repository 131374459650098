const ArrowRight = () => (
  <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M0.999999 1L9 9L1 17" stroke="#260084" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
  // <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
  //   <path d="M9 6L15 12L9 18" stroke="#E4E4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  // </svg>
);

export default ArrowRight;
