import { baseQuery } from './baseQuery';

import { createApi } from '@reduxjs/toolkit/query/react';

export const timecodeApi = createApi({
  reducerPath: 'timecode',
  tagTypes: ['timecode'],
  baseQuery,
  endpoints: () => ({}),
});
