import { Title, Wrapper, Description, Content } from './NotFoundPage.styled';

import GoHomeButton from '../../components/Shared/Buttons/GoHomeButton/GoBackButton';

const NotFoundPage = () => {
  return (
    <Wrapper>
      <Content>
        <Title>ОЙ!</Title>
        <Description>кажется, что-то пошло не так...</Description>
        <GoHomeButton />
      </Content>
    </Wrapper>
  );
};

export default NotFoundPage;
