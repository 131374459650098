import { createSlice } from '@reduxjs/toolkit';

import { quiz, } from '@/api';
import { QuizSlice } from '@/types';

interface QuizState {

  done: boolean;
  activeQuestionIndex: number;
  correctCount: number;
  answers: string[][];
  questions: QuizSlice[];
  starts: Array<string | number>;

}

const initialState: QuizState = {

  done: false,
  activeQuestionIndex: 0,
  correctCount: 0,
  answers: [[]],
  questions: [],
  starts: [],


};

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuizRestart: (state) => {
      state.done = false;
      state.activeQuestionIndex = 0;
      state.answers = state.answers.map((a) => a.sort(() => Math.random() - 0.5));
      state.questions = state.questions.map((q) => ({ ...q, answer: null }));
      state.correctCount = 0;

    },
    setQuizDone: (state) => {
      state.done = true;
    },
    setActiveQuestionIndex: (state, { payload }: { payload: number }) => {
      state.activeQuestionIndex = payload;
    },

    setQuizAnswer: (state, { payload: { answer, question } }: { payload: { question: string; answer: string } }) => {
      const i = state.questions.findIndex((q) => q.question === question)
      state.questions[i].answer = answer;
      // state.answers[i] = answer;

      if (answer === state.questions[i].correct_answer) {
        state.correctCount++;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(quiz.endpoints.getVideoQuiz.matchFulfilled, (state, { payload }) => {
        state.done = false;
        state.activeQuestionIndex = 0;
        state.questions = payload.map((item) => item.quiz.map((q) => ({
          ...q,
          start: item.chapter.start,
          answers: [q.correct_answer, ...q.wrong_answers].sort(() => Math.random() - 0.5),
          answer: null
        }))).flat();
        state.answers = payload.map((q) => q.quiz[0].wrong_answers.concat(q.quiz[0].correct_answer).flat()).sort(() => Math.random() - 0.5);
      })
      .addMatcher(quiz.endpoints.getVideoQuiz.matchPending, (state) => {
        state.done = false;
        state.activeQuestionIndex = 0;
        state.correctCount = 0;
        state.questions = [];
        state.answers = [[]];

      })
  }

});



export default quizSlice.reducer;
