import { Main, Wrapper } from '../../App.styled';
import Container from '../../components/Shared/Container/Container';

import styled from 'styled-components';

export const SearchContainer = styled(Container)`
  padding: 0 2px;
`;

export const SearchWrapper = styled(Wrapper)`
  grid-template-rows: auto 480px auto;
  gap: 22px;
  background: url('/images/bg/ellipse-5.png') no-repeat 50% 82%;
  @media screen and (max-width: 1000px) {
    background: url('/images/bg/ellipse-5.png') no-repeat 40% 45%;
  }
  @media screen and (max-width: 600px) {
    grid-template-rows: auto 520px auto;
  }
`;

export const SearchMain = styled(Main)`
  background: url('/images/bg/5.png') no-repeat 98% 135%, url('/images/bg/12.png') no-repeat 100% 15%,
    url('/images/bg/20.png') no-repeat 0% -5130%, url('/images/bg/21.png') no-repeat 0% 157%;

  @media screen and (max-width: 1000px) {
    background: url('/images/bg/5s.png') no-repeat 0% 5%, url('/images/bg/20r.png') no-repeat 100% 125%,
      url('/images/bg/21s.png') no-repeat 0% 110%;
  }
  @media screen and (max-width: 600px) {
    background: url('/images/bg/5s.png') no-repeat 0% 25%, url('/images/bg/20r.png') no-repeat 100% 135%,
      url('/images/bg/21s.png') no-repeat 0% 110%;
  }
  @media screen and (max-width: 480px) {
    background: none;
  }
`;

export const ResultWrapper = styled(Wrapper)`
  grid-template-rows: auto 1fr auto;
  gap: 22px;
`;

export const AuthWrapper = styled(Wrapper)`
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: center;
`;
