import { SearchVideoCardProps } from './SearchVideoCard.props';
import { VideoImageWrapper, PlayButton, VideoTime } from './SearchVideoCard.styled';

import { memo } from 'react';

import PlayIcon from '@/components/SvgIcons/PlayIcon';

const SearchVideoCard = ({ movie: { thumbnailUrl } }: SearchVideoCardProps) => {
  return (
    <VideoImageWrapper background_image={thumbnailUrl}>
      <PlayButton>
        <PlayIcon />
      </PlayButton>
      {/* <VideoTime>0:52</VideoTime> */}
    </VideoImageWrapper>
  );
};

export default memo(SearchVideoCard);
