import { SearchInput } from './components/SearchInput/SearchInput';
import { FilterAndInputWrapper } from './SearchResultPage.styled';

import { ResultInner } from '../components/ResultInner';

import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useGetSearchVideosQuery, useGetSuggestionsQuery, useLazyGetSearchVideosQuery } from '@/api/searchApi';
import { MyInput } from '@/components/Shared/MyInput';
import { useAppDispatch, useHandlingError } from '@/hooks';
import { Inner } from '@/pages/MainPage/MainPage.styled';
import { setSearchQuery } from '@/store/slices/searchQuery';
import { Video } from '@/types';

export const SearchResultPage = () => {
  const [params] = useSearchParams();
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filteredData, setFilteredData] = useState<Video[]>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setSearchQuery(''))
  }, []);

  const [getVideosQuery] = useLazyGetSearchVideosQuery()

  const { data, isFetching, isLoading, error } = useGetSearchVideosQuery(
    { query: params.get('search') },
    { skip: !params.get('search') },
  );

  const { data: suggestions = [] } = useGetSuggestionsQuery(
    { query: params.get('search') },
    { skip: !params.get('search') },
  );
  const { catchError } = useHandlingError();

  useEffect(() => {
    catchError(error);
  }, [error]);

  const filteredVideos = async () => {
    setIsChecked(prevIsChecked => !prevIsChecked)
    setLoading(true)
    const { data } = await getVideosQuery({ query: params.get('search'), only_transcripts: +!isChecked })
    setFilteredData(data)
    setLoading(false)
  }

  return (
    <>
      <FilterAndInputWrapper>
        <SearchInput suggestionsList={suggestions} />
        <MyInput type='checkbox' label='Искать по точному совпадению' checked={isChecked} onChange={filteredVideos} />
      </FilterAndInputWrapper>
      <Inner>
        <ResultInner data={isChecked ? filteredData : data} isLoading={isLoading || isFetching || loading} count={isChecked ? filteredData?.length : data?.length} />
      </Inner>
    </>
  );
};

export default SearchResultPage;
