import { api } from './api';

import { Video } from '../types/contentTypes';

const moviePath = 'videos';

export const videosAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getMovieById: build.query<Video, { id: string }>({
      query: ({ id }) => ({
        url: `${moviePath}/${id}/`,
        method: 'GET',
      }),
      providesTags: [{ type: 'movies', id: 'ONE' }],
    }),
  }),
});

export const { useGetMovieByIdQuery } = videosAPI;
