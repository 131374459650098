import { TitleH2, Text } from '../../../styles';
import { SearchInput } from '../SearchPage.styled';

import styled from 'styled-components';

export const CountTitle = styled(TitleH2)`
  margin-bottom: 30px;
  margin-left: 0;
`;

export const CardsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const SearchResultInput = styled(SearchInput)`
  width: 100%;
  margin-bottom: 60px;

  @media screen and (max-width: 480px) {
    margin-bottom: 20px;
  }

  input {
    width: 100%;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SearchVideoCardStyled = styled.div`
  width: 100%;
  border-radius: 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px 10px 0px 0px;
  background: #210071;
  padding: 26px 30px;

  @media screen and (max-width: 480px) {
    padding: 13px 10px;
  }
`;

export const Main = styled(Header)`
  border-radius: 0px 0px 10px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background: #1c0053;
`;

export const DescriptionText = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 480px) {
    font-size: 17px;
  }
`;
export const InfoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;
export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
  border-radius: 10px;
  background: #210071;
  padding: 26px 30px;
  border: 1px solid transparent;
  &:hover {
    background-color: #371190;
    border: 1px solid #e4e4ff;
  }
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 480px) {
    padding: 13px 10px;
    gap: 15px;
  }
`;
export const InfoText = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 28px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }
`;
export const InfoTime = styled(InfoText)`
  font-weight: 700;
`;
