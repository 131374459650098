import styled from 'styled-components';

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  position: relative;

  height: 100%;
  width: 100%;
`;
export const ExitButtonWrapper = styled.button`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 149px;
  gap: 10px;
  margin-top: 20px;

  @media (max-width: 620px) {
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
  }
  /* display: grid;
  grid-template-columns: 149px 149px;
  gap: 10px;
  margin-top: 40px;

  @media (max-width: 620px) {
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
  } */
`;

export const PasswordWrapper = styled.div`
  p:last-child {
    padding-top: 5px;
    cursor: pointer;
    line-height: 18px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const ToRegistrationButton = styled.div`
  margin-top: 20px;
  cursor: pointer;
  width: max-content;
  display: flex;

  align-items: center;
  gap: 4px;

  @media screen and (max-width: 350px) {
    flex-direction: column;
    margin: 20px auto 0;
  }
`;