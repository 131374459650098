import { Slide, SliderContainer, SliderStyled } from './InnerSlide.styled';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { secondsToTime } from '../utils';

import { StyledLink } from '@/components';
import { FragmentCard } from '@/components/Card/FragmentCard/FragmentCard';
import { ArrowButton } from '@/components/Shared/Buttons';
import { Video } from '@/types';

type InnerSliderProps = {
  items: Video;
};

const InnerSlider = ({ items }: InnerSliderProps) => {
  const settings = {
    dots: false,
    infinite: false,

    speed: 500,
    slidesToScroll: 1,
    nextArrow: <ArrowButton variant="right" />,
    prevArrow: <ArrowButton />,

    variableWidth: true,

    accessibility: true,
    swipeToSlide: true,
  };

  if (items.cues.length === 0) {
    return null;
  }

  return (
    <SliderContainer>
      <SliderStyled {...settings} className="slider" cssEase="linear">
        {items.cues.map(({ content, image, timestampLink }, i) => (
          <div key={i}>
            <Slide index={i}>
              <StyledLink to={`${items.publicId}?t=${parseInt(timestampLink)}`}>
                <FragmentCard
                  background_image={`${import.meta.env.VITE_IMAGE_URL}${image}`}
                  timeStamp={secondsToTime(parseInt(timestampLink))}
                  content={content}
                />
              </StyledLink>
            </Slide>
          </div>
        ))}
      </SliderStyled>
    </SliderContainer>
  );
};

export default InnerSlider;
