import { quizApi } from './api';


import { QuizResults } from '@/types';

const QUIZ_PATH = '/quiz/';

export const quiz = quizApi.injectEndpoints({
  endpoints: (build) => ({
    getVideoQuiz: build.query<QuizResults[], { publicId: string | null }>({
      query: ({ publicId = '' }) => ({
        url: `${QUIZ_PATH}${publicId}/`,
        method: 'GET',

      }),
      // transformResponse: (data: Record<string, QuizResults[]>) => JSON.parse(data) Object.values(data).flat().slice(0, 10),
      transformResponse: ({ data }: { data: string }) => {
        try {
          return JSON.parse(data);
        } catch (e) {
          throw new Error('Ошибка чтения данных');
        }
      },

    }),
  }),
})

export const {
  useGetVideoQuizQuery
} = quiz;
