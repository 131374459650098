import React from 'react';

const PlayIcon = () => (
  <svg width="69" height="68" viewBox="0 0 69 68" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M30.7101 68C28.2883 68 25.8772 67.3324 23.7318 66.01C19.8178 63.5964 17.4819 59.4241 17.4819 54.8507V28.4486C17.4819 25.3392 20.0209 22.7021 23.1536 22.7021C26.2864 22.7021 28.8254 25.3392 28.8254 28.4486V54.8497C28.8254 55.8003 29.3673 56.261 29.7189 56.4347C30.3448 56.7441 31.0509 56.7548 31.7198 56.2161C36.9462 52.0057 49.7183 41.7355 55.7562 36.8771C56.596 36.2018 57.1361 35.7265 57.1361 34.9867C57.1361 34.3035 56.93 33.8136 55.9574 33.3012C45.8971 28.0055 25.1887 17.0961 14.6412 11.5516C13.8648 11.1436 13.0631 11.4062 12.737 11.6072C12.403 11.8131 11.8434 12.2943 11.8434 13.2029V17.0122C11.8434 20.1216 9.4265 22.7079 6.29475 22.7079C3.16301 22.7079 0.5 20.1216 0.5 17.0122V13.2029C0.5 8.62951 2.83685 4.45722 6.74982 2.04363C10.6628 -0.370927 15.5328 -0.732038 19.5971 1.42291C30.09 6.98695 50.7671 17.8388 61.2082 23.3209C65.6602 25.6583 68.5 30.0541 68.5 35.0599C68.5 39.8568 65.4727 43.503 61.7619 46.5061C55.5131 51.5636 44.9548 60.1102 38.6337 65.2009C36.2119 67.1519 32.8428 68 30.7101 68Z"
      fill="#E4E4FF"
    />
  </svg>
);

export default PlayIcon;
