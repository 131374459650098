import {
  InputWrapper,
  SearchInput,
  SuggestionsItem,
  SuggestionsList,
  SuggestionsWrapper,
} from '../../SearchPage.styled';
import { OnSearchHandler } from '../SearchWithButton/SearchWithButton';

import {
  ChangeEvent,
  RefObject,
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import escapeStringRegexp from 'escape-string-regexp';
import ga4 from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { useLazyGetSuggestionsQuery } from '@/api/searchApi';
import { SearchIcon } from '@/components';
import { useActions, useAppDispatch, useAppSelector, useDebounce, useOutsideClick } from '@/hooks';
import { setSearchQuery } from '@/store/slices/searchQuery';
import { LabelText } from '@/styles';

// eslint-disable-next-line @typescript-eslint/ban-types
export const InputWithDropDown = forwardRef<OnSearchHandler, {}>((_, ref) => {
  const navigate = useNavigate();
  const search = useRef<HTMLInputElement | null>(null);
  const { showNotification } = useActions();
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(-1);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const searchQuery = useAppSelector((state) => state.searchQuery.query);

  const [getSuggestions] = useLazyGetSuggestionsQuery();

  useEffect(() => {
    search.current?.focus();
    if (searchQuery) {
      navigate(`results?search=${searchQuery}`);
    }
  }, []);

  const wrapperRef = useOutsideClick<HTMLDivElement>(() => {
    setOpen(false);
  });

  useImperativeHandle(
    ref,
    () => ({
      onSearch: () => {
        onSearchHandler();
      },
    }),
    [],
  );

  const debounceSuggestions = useDebounce(async (query: string) => {
    try {
      const data = query ? await getSuggestions({ query }).unwrap() : [];
      setOpen(true);
      setSuggestions(data);
    } catch (error) {
      console.log(error);
    }
  }, 250);

  const onSearchHandler = () => {
    const value = searchQuery ? searchQuery : search.current?.value;
    dispatch(setSearchQuery(value || ''));
    if (value) {
      navigate(`results?search=${value}`);
      ga4.event({
        category: 'Search',
        action: 'Search',
        label: 'Search',
      });
    } else {
      showNotification({ severity: 'error', text: 'Пожалуйста введите поисковый запрос' });
    }
  };

  const pickSuggestion = (value: string) => {
    dispatch(setSearchQuery(value));
    navigate(`results?search=${value}`);
  };

  const onChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    await debounceSuggestions(e.target.value);
  }, []);

  const refs = useMemo(
    () =>
      suggestions.reduce((acc, item) => {
        acc[item] = createRef<HTMLLIElement>();
        return acc;
      }, {} as Record<string, RefObject<HTMLLIElement>>),
    [suggestions],
  );

  const scrollToRef = (value: string, block: 'start' | 'end' | 'center' | 'nearest') => {
    const ref = refs[value].current;
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth', block });
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    const searchInput = search.current;
    if (selectedSuggestion < suggestions.length) {
      switch (e.key) {
        case 'Escape':
          setSelectedSuggestion(-1);
          setOpen(false);
          break;
        case 'Enter':
          onSearchHandler();
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (selectedSuggestion > 0) {
            setSelectedSuggestion((prev) => prev - 1);
            searchInput && (searchInput.value = suggestions[selectedSuggestion - 1]);
            scrollToRef(suggestions[selectedSuggestion - 1], 'start');
          } else {
            setSelectedSuggestion(-1);
          }

          break;
        case 'ArrowDown':
          e.preventDefault();
          if (selectedSuggestion < suggestions.length - 1) {
            setSelectedSuggestion((prev) => prev + 1);
            searchInput && (searchInput.value = suggestions[selectedSuggestion + 1]);

            scrollToRef(suggestions[selectedSuggestion + 1], 'end');
          } else {
            setSelectedSuggestion(suggestions.length - 1);
          }
          break;
        default:
          break;
      }
    } else {
      setSelectedSuggestion(-1);
    }
  };

  return (
    <InputWrapper ref={wrapperRef}>
      <SearchInput
        icon={<SearchIcon />}
        ref={search}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onFocus={() => setOpen(true)}
        placeholder={'Введите запрос или опишите видео'}
      />
      {suggestions.length > 0 && open && (
        <SuggestionsWrapper>
          <SuggestionsList>
            {suggestions.map((suggestion, i) => (
              <SuggestionsItem
                key={suggestion}
                onClick={(e) => {
                  e.stopPropagation();
                  pickSuggestion(suggestion);
                }}
                selected={selectedSuggestion === i}
                ref={refs[suggestion]}
                onMouseEnter={() => {
                  setSelectedSuggestion(i);
                }}
              >
                <LabelText dangerouslySetInnerHTML={{ __html: highlightText(suggestion, search.current!.value) }} />
              </SuggestionsItem>
            ))}
          </SuggestionsList>
        </SuggestionsWrapper>
      )}
    </InputWrapper>
  );
});

InputWithDropDown.displayName = 'InputWithDropDown';

export const highlightText = (text: string, search: string) => {
  const escapedString = escapeStringRegexp(search);
  const regex = new RegExp(`(${escapedString})`, 'gi');
  return text.replace(regex, '<mark>$1</mark>');
};
