import { useActions } from './useActions';

import { isFetchBaseQueryError, isSerializedError } from '../utils';
import { isAuthData } from '../utils/isAuthData';

import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

export const useHandlingError = () => {
  const { showNotification } = useActions();
  const navigate = useNavigate();

  const ErrorLogout = (code: string | number, message = '', data?: unknown) => {
    switch (code) {
      case '401':
      case 401:
      case '403':
      case 403:
        if (isAuthData(data)) {
          if ('code' in data) {
            setTimeout(() => {
              showNotification({
                text: `Время сессии истекло! ${message}`,
                severity: 'error',
              });
            }, 1000);
            navigate('/', { replace: true });
          }
        }

        break;
      case '500':
      case 500:
        showNotification({
          text: `Сервер не отвечает. ${message}`,
          severity: 'error',
        });
        break;
      case 'FETCH_ERROR':
        showNotification({
          text: `Ошибка соединения. ${message} `,
          severity: 'error',
        });
        break;
      case '404':
      case 404:
        navigate('/404', { replace: true });
        break;
      default:
        showNotification({
          text: `${code} ${message}`,
          severity: 'error',
        });
    }
  };

  const catchError = useCallback(
    (error: unknown, text?: string) => {
      if (isFetchBaseQueryError(error)) {
        const code = error.status as string;
        ErrorLogout(code, text, error.data);
      } else if (isSerializedError(error)) {
        const message = error.message as string;
        const code = error.code as string;
        ErrorLogout(code, message);
      }
    },
    [ErrorLogout],
  );

  return { catchError };
};
