import { RootState } from '../store';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  query: '',
};

export const searchQuerySlice = createSlice({
  name: 'searchQuery',
  initialState,
  reducers: {
    setSearchQuery: (state, { payload }: { payload: string }) => {
      state.query = payload;
    },
  },
});

export const notification = (state: RootState) => state.notification;

export const { setSearchQuery } = searchQuerySlice.actions;

export default searchQuerySlice.reducer;
