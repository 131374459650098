import Header from './Header';
import { SearchContainer, SearchMain, SearchWrapper } from './Search.styled';

import { Search } from '../../pages';

import { useMatchMedia } from '@/hooks';

const SearchLayout = () => {
  const isMobile = useMatchMedia('(max-width: 1000px)');

  return (
    <SearchWrapper isMobile={isMobile} isSearch>
      <Header />
      <SearchMain>
        <SearchContainer>
          <Search />
        </SearchContainer>
      </SearchMain>
    </SearchWrapper>
  );
};

export default SearchLayout;
