import { Video } from '../../types/contentTypes';
import { RootState } from '../store';

import { createSlice } from '@reduxjs/toolkit';

import { searchAPI } from '@/api/searchApi';

const initialState: { videos: Video[] } = {
  videos: [],
};

export const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(searchAPI.endpoints.getSearchVideos.matchFulfilled, (state, action) => {
      state.videos = action.payload;
    }

    )
  }
});

export const videos = (state: RootState) => state.videos;


export default videoSlice.reducer;
