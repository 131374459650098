import { ContainerProps } from './Container.props';
import { ContainerStyled } from './Container.styled';

import React from 'react';

const Container: React.FC<ContainerProps> = ({ children, classNames }) => (
  <ContainerStyled className={classNames}>{children}</ContainerStyled>
);

export default Container;
