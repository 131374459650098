import styled from 'styled-components';

type PropsType = {
  isMobile: boolean;
  isSearch?: boolean;
};

export const Wrapper = styled.div<PropsType>`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 20px;
`;

export const Main = styled.main`
  position: relative;
`;

export const InDev = styled.div`
  background-image: url('./images/page-in-dev.png');
  width: 100%;
  height: 100vh;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
`;
