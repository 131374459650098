import { SearchButton, SearchWrapper, TextWithLogo } from '../../SearchPage.styled';
import { InputWithDropDown } from '../InputWithDropDown/InputWithDropDown';

import { useRef } from 'react';

import { LogoIcon } from '@/components';
import { TitleH5 } from '@/styles';

export type OnSearchHandler = {
  onSearch: () => void;
};

export const SearchWithButton = () => {
  const search = useRef<OnSearchHandler>(null);

  return (
    <SearchWrapper>
      <InputWithDropDown ref={search} />
      <TextWithLogo>
        <TitleH5>Экономьте время - ищите сразу то, что нужно с</TitleH5>
        <a target="_blank" href="https://search.visaver.com" rel="noreferrer">
          <LogoIcon />
        </a>
      </TextWithLogo>
      <SearchButton theme="white" onClick={() => search.current?.onSearch()}>
        Поиск
      </SearchButton>
    </SearchWrapper>
  );
};
