const EyeHiddenIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M8.42188 11.9998C8.42188 11.53 8.51442 11.0647 8.69423 10.6306C8.87404 10.1965 9.13759 9.80208 9.46984 9.46984C9.80208 9.13759 10.1965 8.87404 10.6306 8.69423C11.0647 8.51442 11.53 8.42188 11.9998 8.42188"
      stroke="#62548B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.41984 19.53C9.55984 20.01 10.7698 20.27 11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39998C20.7798 8.87998 20.4198 8.38998 20.0498 7.92998M3.89396 16C3.54069 15.5621 3.20493 15.0945 2.88984 14.6C1.98984 13.19 1.98984 10.82 2.88984 9.40998C5.17984 5.80998 8.46984 3.72998 11.9998 3.72998C13.2172 3.72998 14.4103 3.97898 15.5426 4.45257"
      stroke="#62548B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.51 12.7C15.3745 13.3976 15.0337 14.0387 14.5312 14.5412C14.0287 15.0437 13.3876 15.3845 12.69 15.52M2 22L14.53 9.47L22 2"
      stroke="#62548B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EyeHiddenIcon;