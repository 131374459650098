import { Login } from "@/components/Shared/Login/Login"
import { useMatchMedia } from "@/hooks";
import { AuthWrapper } from "@/layouts/Search/Search.styled"

export const AuthPage = () => {
  const isMobile = useMatchMedia('(max-width: 1000px)');

  return (
    <AuthWrapper isMobile={isMobile} isSearch><Login /></AuthWrapper>
  )
}
