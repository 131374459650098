import { FragmentCardProps } from './FragmentCard.props';
import { VideoImageWrapper, Description, FragmentCardStyled, Time } from './FragmentCard.styled';

export const FragmentCard = ({ background_image, content = '', timeStamp = '' }: FragmentCardProps) => {
  return (
    <FragmentCardStyled>
      <VideoImageWrapper background_image={background_image} />
      <Description dangerouslySetInnerHTML={{ __html: content }} />
      <Time>{timeStamp}</Time>
    </FragmentCardStyled>
  );
};
