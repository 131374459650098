import { InDev } from './App.styled';
import ScrollToTop from './components/ScrollToTop';
import FullScreenLoader from './components/Shared/FullScreenLo/FullScreenLoader';
import { Login } from './components/Shared/Login/Login';
import { Notification } from './components/Shared/Notification/Notification';
import * as analytics from './ga4';
import { ProtectedRoute } from './HOC/ProtectedRoute';
import { useActions } from './hooks';
import ResultsLayout from './layouts/Search/Result/Result';
import SearchLayout from './layouts/Search/Search';
import { NotFoundPage, Video, SearchResult, QuizPage as Quiz } from './pages';
import { AuthPage } from './pages/AuthPage/AuthPage';
import { ModalType } from './store/slices/modalsSlice';
import Global from './styles/global';

import { Suspense, useEffect } from 'react';

import { Route, Routes, useSearchParams } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';

analytics.init();
function App() {
  const [params] = useSearchParams();
  const { openModal } = useActions();
  const isAuth = JSON.parse(localStorage.getItem('isAuth') || '""');
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  useEffect(() => {
    if (!isAuth && params.has('popup')) {
      openModal({ active: params.get('popup') as ModalType });
    }
  }, [isAuth, params]);

  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<FullScreenLoader />}>
        <Routes>
          <Route path="/" element={<SearchLayout />} errorElement={<InDev />} />
          <Route path="/login" element={<AuthPage />} />
          <Route path="results" element={<ProtectedRoute component={ResultsLayout} />}>
            <Route index element={<SearchResult />} />
            <Route path=":id" element={<Video />} />
          </Route>
          <Route path="quiz" element={<ProtectedRoute component={ResultsLayout} />}>
            <Route path=":id" element={<Quiz />} />
          </Route>
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
      <Global />
      {/* <Login /> */}
      <Notification />
      <YMInitializer accounts={[97245013]} />
    </>
  );
}

export default App;
